import { useState } from 'react';
import { useUserContext } from '../components/Providers/UserContext';
import { useTimeZoneContext } from '../providers/TimeZoneProvider';
import SessionDashboardDateNav from '../components/Session/SessionDashboardDateNav';
import SessionDashboardTable from '../components/Session/SessionDashboardTable';
import useFetchDailySessions from '../Query/useFetchDailySessions';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const PublicSession = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { hasRole, userToken } = useUserContext();
  const { timeZone, setTimeZone, timezoneOffset } = useTimeZoneContext();

  const [dailySessionsDate, setDailySessionsDate] = useState(dayjs());

  const {
    data: dailySessions,
    isPending,
    error,
  } = useFetchDailySessions(userToken, dailySessionsDate, timezoneOffset);

  if (error) {
    console.log('An error has occurred: ' + error.message);
  }

  const handleTimeZoneChange = (selectedTimezone: string) => {
    setTimeZone(selectedTimezone);
  };

  return (
    <section className="public-group-section">
      <div className="session-dashboard-table-nav-container">
        {hasRole('Admin') && (
          <>
            <SessionDashboardDateNav
              addBtnRoute={'/sessionadd'}
              setDailySessionsDate={setDailySessionsDate}
              dailySessionsDate={dailySessionsDate}
            />
            <SessionDashboardTable
              sessions={dailySessions}
              isLoading={isPending}
              tableTimezone={timeZone}
              handleTimeZoneChange={handleTimeZoneChange}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default PublicSession;
