// import { SHA1 } from "crypto-js";

import {
  ClassMaterial,
  ExclusionInterval,
  GSELevel,
  Location,
  PrivateSessionData,
  TimeFrame,
  Topic,
} from './types';
import { SessionData } from '@pages/SessionAdd';
import { TutorAddEdit } from '@components/Tutor/TutorAddEdit';
import dayjs from 'dayjs';

export const getContentEnv = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params.contentEnv || process.env.REACT_APP_CONTENT_ENV || 'prod';
};

// export const buildHash = (id: string) => {
//   return SHA1(id + process.env.REACT_APP_HASHSALT).toString();
// };

export const tutoringApiUrl = () => {
  switch (getContentEnv()) {
    case 'prod':
      return 'https://api.tutoring-prd.pearsonprd.tech';
    case 'stage':
      return 'https://api.tutoring-stg.pearsondev.tech';
    default:
      return 'https://api.tutoring-dev.pearsondev.tech';
  }
};

export const tutoringBasePath = () => {
  switch (getContentEnv()) {
    case 'prod':
      return 'https://d11s3x8mtmgzr2.cloudfront.net';
    case 'stage':
      return 'https://do7jjypgsyqqw.cloudfront.net';
    default:
      return 'https://d24mswg82xnqvq.cloudfront.net';
  }
};
// const adminApiUrl = () => {
//   switch (getContentEnv()) {
//     case "stage":
//     case "qa":
//       return "https://sgge75fznh.execute-api.us-east-1.amazonaws.com/dev/";
//     default:
//       return "https://sgge75fznh.execute-api.us-east-1.amazonaws.com/dev/";
//   }
// };

export const apiGetUserList = async (fromDate: string, token: string) => {
  const url = `${tutoringApiUrl()}/_internal/sessions/getuserlist/${fromDate}?csv=true`;
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(url, requestOptions);
    return res.blob();
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

export const getPollsData = async (fromDate: string, token: string) => {
  const url = `${tutoringApiUrl()}/_internal/sessions/getuserpolls/${fromDate}?csv=true`;
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(url, requestOptions);
    return res.blob();
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

export const getUserFeedbacksData = async (fromDate: string, token: string) => {
  const url = `${tutoringApiUrl()}/_internal/feedback/responses/${fromDate}?csv=true`;
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(url, requestOptions);
    return res.blob();
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

// const generalGet = async (url: string, token: string, callback: any) => {
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   try {
//     const res = await fetch(url, requestOptions);
//     if (!res.ok) {
//       return {};
//     }
//     const data = await res.json();
//     callback(data);
//     return;
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// export const getLangDescription = async (
//   templateId: number | string,
//   language: string,
//   setNewTemplateLang: any,
//   userToken: string
// ) => {
//   return await generalGet(
//     tutoringApiUrl() +
//       `/_internal/templates/${templateId}/getLangDescription/${language}`,
//     userToken,
//     setNewTemplateLang
//   );
// };

// export const addLangDescription = async (
//   templateId: number | string,
//   language: string,
//   data: any,
//   updateTemplateLang: any,
//   userToken: string
// ) => {
//   return await generalSave(
//     tutoringApiUrl() +
//       `/_internal/templates/${templateId}/addLangDescription/${language}`,
//     data,
//     userToken,
//     updateTemplateLang
//   );
// };

// export const getSessionData = async (
//   sessionId: string | number,
//   userToken: string,
//   saveData: any
// ) => {
//   return await generalGet(
//     tutoringApiUrl() + `/_internal/sessions/${sessionId}/getSessionData`,
//     userToken,
//     saveData
//   );
// };

// export const generalDelete = async (
//   url: string,
//   token: string,
//   callback: any
// ) => {
//   const requestOptions = {
//     method: "DELETE",
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   try {
//     const res = await fetch(url, requestOptions);
//     if (!res.ok) {
//       return {};
//     }
//     const data = await res.json();
//     callback(data);
//     return;
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// export const generalSave = async (
//   url: string,
//   data: string,
//   token: string,
//   callback: any
// ) => {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(data),
//   };

//   try {
//     const res = await fetch(url, requestOptions);
//     if (!res.ok) {
//       return {};
//     }
//     const data = await res.json();
//     callback(data);
//     return;
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// // export const getTutors = async (token: string, callback: any) => {
// //   return generalGet(tutoringApiUrl() + "/_internal/tutors/all", token, callback);
// // };

// export const deleteTutor = async (
//   tutorId: string | number,
//   token: string,
//   callback: any
// ) => {
//   generalDelete(
//     tutoringApiUrl() + "/_internal/tutors/" + tutorId,
//     token,
//     callback
//   );
// };

// export const saveTutor = async (
//   tutorId: string | number,
//   data: any,
//   token: string,
//   callback: any
// ) => {
//   generalSave(
//     tutoringApiUrl() + "/_internal/tutors/" + (tutorId ? tutorId : ""),
//     data,
//     token,
//     callback
//   );
// };

// export const getTemplates = async (token: string, callback: any) => {
//   generalGet(tutoringApiUrl() + "/_internal/templates/all", token, callback);
// };

// export const saveTemplate = async (
//   templateId: string | number,
//   data: any,
//   token: string,
//   callback: any
// ) => {
//   generalSave(
//     tutoringApiUrl() + "/_internal/templates/" + (templateId ? templateId : ""),
//     data,
//     token,
//     callback
//   );
// };

// export const uploadFile = async (
//   templateId: string | number,
//   data: any,
//   token: string,
//   callback: any
// ) => {
//   uploadFileToSession(
//     tutoringApiUrl() +
//       "/_internal/templates/uploadFile/" +
//       (templateId ? templateId : ""),
//     data,
//     token,
//     callback
//   );
// };

// export const deleteTemplate = async (
//   templateId: string | number,
//   token: string,
//   callback: any
// ) => {
//   generalDelete(
//     tutoringApiUrl() + "/_internal/templates/" + templateId,
//     token,
//     callback
//   );
// };

// export const getSessions = async (token: string, callback: any) => {
//   generalGet(tutoringApiUrl() + "/_internal/sessions/all", token, callback);
//   // generalGet("http://localhost:8080/_internal/sessions/all", token, callback);
// };

// export const saveSession = async (
//   sessionId: string | number,
//   data: any,
//   token: string,
//   callback: any
// ) => {
//   generalSave(
//     tutoringApiUrl() + "/_internal/sessions/" + (sessionId ? sessionId : ""),
//     data,
//     token,
//     callback
//   );
// };

// export const deleteSession = async (
//   sessionId: string | number,
//   token: string,
//   callback: any
// ) => {
//   generalDelete(
//     tutoringApiUrl() + "/_internal/sessions/" + sessionId,
//     token,
//     callback
//   );
// };

// export const joinSession = async (
//   sessionId: string | number,
//   tutorEmail: string,
//   callback: any
// ) => {
//   const requestOptions = {
//     method: "GET",
//   };
//   const urlSearchParams = new URLSearchParams(window.location.search);
//   const params = Object.fromEntries(urlSearchParams.entries());
//   const url = `${tutoringApiUrl()}/sessions/${sessionId}/joinSessionTutor
//                 ${params.force ? "&force=true" : ""}`;
//   try {
//     const res = await fetch(url, requestOptions);
//     const data = await res.json();
//     callback(data);
//     return;
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// export const uploadFileToSession = async (
//   url: string,
//   inputData: any,
//   token: string,
//   callback: any
// ) => {
//   const data = new FormData();
//   data.append("project", inputData.project);
//   data.append("mondlyTopic", inputData.mondlyTopic);
//   data.append("id", inputData.id);
//   data.append("upload", inputData.uploadToSession);
//   data.append("files", inputData.materialFile);
//   data.append("discipline", inputData.discipline);
//   data.append("level", inputData.level);
//   data.append("title", inputData.title);
//   data.append("description", inputData.description);
//   //   for(let i =0; i < inputData.materialFiles.length; i++) {
//   //     data.append("files", inputData.materialFiles[i]);
//   // }
//   // data.append('deletedFiles', inputData.deletedFiles)
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     body: data,
//   };

//   try {
//     const res = await fetch(url, requestOptions);
//     if (!res.ok) {
//       return {};
//     }
//     const data = await res.json();
//     callback(data);
//     return;
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// export const addUserToBlockList = async (
//   userId: string | number,
//   token: string
// ) => {
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   try {
//     const res = await fetch(
//       `${tutoringApiUrl()}/_internal/updateBlockUsersList?userId=${userId}`,
//       requestOptions
//     );
//     if (!res.ok) {
//       return {};
//     }
//     return await res.json();
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// export const removeUserBlockList = async (
//   userId: string | number,
//   token: string
// ) => {
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   try {
//     const res = await fetch(
//       `${tutoringApiUrl()}/_internal/removeUserFromBlock?userId=${userId}`,
//       requestOptions
//     );
//     if (!res.ok) {
//       return {};
//     }
//     return await res.json();
//   } catch (e) {
//     console.log("error:", e);
//     throw e;
//   }
// };

// export const getBlockUsersList = async () => {
//   try {
//     const resp = await fetch(`${tutoringApiUrl()}/getBlockUsersList`);
//     return await resp.json();
//   } catch (e) {
//     console.log(e);
//     return undefined;
//   }
// };

// *************************************************************************************

// export const getLocation = async (token: string) => {
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   try {
//     const tutors = await fetch(tutoringApiUrl() + "/_internal/sessions/all", requestOptions);
//     // const tutors = await fetch("http://localhost:8080/_internal/sessions/all", requestOptions);
//     // if (!tutors.ok) {
//     //   return {};
//     // }
//     return tutors.json()
//   } catch (e) {
//     console.log("error:", e);
//     // throw e;
//   }
// };

export const getTutors = async (token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const tutors = await fetch(tutoringApiUrl() + '/_internal/tutors/all', requestOptions);
    // const tutors = await fetch("http://localhost:8080/_internal/tutors/all", requestOptions);
    // if (!tutors.ok) {
    //   return {};
    // }
    return tutors.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getSessionList = async (
  token: string,
  date?: Date | string | dayjs.Dayjs,
  offset?: number,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      day: dayjs(date).format(),
      offset: offset ? offset : 0,
    }),
  };

  try {
    const dailySessions = await fetch(
      tutoringApiUrl() + '/_internal/sessions/dailySessions',
      requestOptions,
    );
    // const tutors = await fetch("http://localhost:8080/_internal/sessions/dailySessions", requestOptions);
    // if (!tutors.ok) {
    //   return {};
    // }
    return dailySessions.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getVendorsList = async (token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const vendors = await fetch(`${tutoringApiUrl()}/_internal/getVendors`, requestOptions);
    return vendors.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getTopicList = async (token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const topics = await fetch(tutoringApiUrl() + '/_internal/topics/all', requestOptions);
    // const topics = await fetch("http://localhost:8080/_internal/topics/all", requestOptions);
    return topics.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getLangData = async (
  topicId: number,
  language: string,
  mondlyCategory: string,
  token: string,
) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ topicId, language, mondlyCategory }),
    };
    const langData = await fetch(
      tutoringApiUrl() + `/_internal/topics/getLangData`,
      requestOptions,
    );
    // const langData = await fetch(`http://localhost:8080/_internal/topics/getLangData`, requestOptions);
    return langData.json();
  } catch (e) {
    console.log('error:', e);
  }
};

export const uploadThumbnail = async (file: File, tutorName: string, token: string) => {
  const data = new FormData();
  data.append('files', file, file.name);
  data.append('tutorName', tutorName);
  // const url = 'http://localhost:8080/_internal/tutors/uploadThumb'
  const url = tutoringApiUrl() + '/_internal/tutors/uploadThumb';
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };

  try {
    const res = await fetch(url, requestOptions);

    return await res.json();
  } catch (e) {
    console.log('error:', e);
    return {};
  }
};

export const saveSession = async (session: SessionData, token: string) => {
  const url = tutoringApiUrl() + '/_internal/sessions/addSession';
  // const url = 'http://localhost:8080/_internal/sessions/addSession'
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(session),
  };
  {
    try {
      const res = await fetch(url, requestOptions);
      return await res.json();
    } catch (e) {
      console.log('error:', e);
      throw e;
    }
  }
};

export const updateTutor = async (data: TutorAddEdit, token: string) => {
  // const url = 'http://localhost:8080/_internal/tutors/updateTutor';
  const url = `${tutoringApiUrl()}/_internal/tutors/updateTutor`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const res = await fetch(url, requestOptions);
    const result = await res.json();
    return result?.resTutor;
  } catch (e) {
    console.log('error', e);
    throw e;
  }
};

export const updateTopic = async (data: Topic, token: string) => {
  // const url = 'http://localhost:8080/_internal/topics/saveTopic';
  // console.log("updateTopic data: ", data);

  const url = `${tutoringApiUrl()}/_internal/topics/saveTopic`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const res = await fetch(url, requestOptions);
    const result = await res.json();
    return result?.topic;
  } catch (e) {
    console.log('error', e);
    throw e;
  }
};

export const uploadTopicFiles = async (
  file: File,
  token: string,
  onProgress: (percent: number, fileName: string) => void,
) => {
  const fileData = await readFileAsArrayBuffer(file);

  return new Promise<ClassMaterial>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = `${tutoringApiUrl()}/_internal/topics/uploadTopicFile?fileName=${file.name}`;
    // const url = `http://localhost:8080/_internal/topics/uploadTopicFile?fileName=${file.name}`;
    xhr.open('PUT', url, true);

    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.setRequestHeader('Content-Type', 'application/octet-stream');
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    };
    xhr.upload.onprogress = (e) => {
      const percent = Math.round((e.loaded / e.total) * 100);
      onProgress(percent, file.name);
    };

    xhr.send(fileData);
  });
};

const readFileAsArrayBuffer = async (
  file: File,
): Promise<string | ArrayBuffer | null | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result);
      } else {
        resolve(undefined);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const deleteSession = async (
  // url: string,
  sessionId: number,
  token: string,
  // callback: any
) => {
  const url = tutoringApiUrl() + '/_internal/sessions/' + `${sessionId}`;
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await fetch(url, requestOptions);
    if (!res.ok) {
      return {};
    }
    return await res.json();
    // callback(data);
    // return;
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

export const addUserToBlockList = async (userId: number, token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(
      `${tutoringApiUrl()}/_internal/updateBlockUsersList?userId=${userId}`,
      requestOptions,
    );
    if (!res.ok) {
      return {};
    }
    return await res.json();
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

export const removeUserBlockList = async (userId: number, token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(
      `${tutoringApiUrl()}/_internal/removeUserFromBlock?userId=${userId}`,
      requestOptions,
    );
    if (!res.ok) {
      return {};
    }
    return await res.json();
  } catch (e) {
    console.log('error:', e);
    throw e;
  }
};

export const getBlockUsersList = async () => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/getBlockUsersList`);
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

// private:

export const getGroups = async (token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const groups = await fetch(tutoringApiUrl() + '/_internal/license/getGroups', requestOptions);
    return groups.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getGroupById = async (groupId: number, rootId: number, token: string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ groupId }),
  };

  try {
    const groups = await fetch(
      tutoringApiUrl() + '/_internal/license/getGroupData',
      requestOptions,
    );
    return groups.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const updateGroupGSELevels = async (
  groupId: number,
  rootId: number,
  levels: GSELevel[],
  token: string,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ groupId, rootId, levels }),
  };

  try {
    const res = await fetch(
      tutoringApiUrl() + '/_internal/license/updateGroupLevel',
      requestOptions,
    );
    const data = await res.json();
    return data;
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const updateGroupLocations = async (
  groupId: number,
  rootId: number,
  locations: Location[],
  token: string,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ groupId, rootId, locations }),
  };

  try {
    const res = await fetch(
      tutoringApiUrl() + '/_internal/license/updateGroupLocation',
      requestOptions,
    );
    const data = await res.json();
    return data;
    // return groups.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const savePrivateSession = async (session: PrivateSessionData, token: string) => {
  const url = tutoringApiUrl() + '/_internal/sessions/addPrivateSession';
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(session),
  };
  {
    try {
      const res = await fetch(url, requestOptions);
      return await res.json();
    } catch (e) {
      console.log('error:', e);
      throw e;
    }
  }
};

export const getGroupHasSessions = async (groupId: number, token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await fetch(
      tutoringApiUrl() + `/_internal/license/groupHasSessions/${groupId}`,
      requestOptions,
    );
    return res.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getDailySessionsExists = async (
  startDate: string,
  endDate: string,
  offset: number,
  token: string,
  groupId?: number,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: groupId
      ? JSON.stringify({ startDate, endDate, groupId, offset })
      : JSON.stringify({ startDate, endDate, offset }),
  };

  try {
    const res = await fetch(
      tutoringApiUrl() + `/_internal/sessions/getDailySessionsExists`,
      requestOptions,
    );
    return res.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const getPrivateSessionList = async (
  token: string,
  groupId: number,
  date?: Date | string | dayjs.Dayjs,
  offset?: number,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      day: date ? date : new Date(),
      offset: offset ? offset : 0,
      groupId,
    }),
  };

  try {
    const dailySessions = await fetch(
      tutoringApiUrl() + '/_internal/sessions/dailyPrivateSessions',
      requestOptions,
    );
    // const tutors = await fetch("http://localhost:8080/_internal/sessions/dailySessions", requestOptions);
    // if (!tutors.ok) {
    //   return {};
    // }
    return dailySessions.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const addOrgExclusionDates = async (
  rootId: number,
  exclusionDates: ExclusionInterval[],
  token: string,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ rootId, exclusionDates }),
  };

  try {
    const res = await fetch(
      tutoringApiUrl() + '/_internal/license/addExclusionDates',
      requestOptions,
    );
    const data = await res.json();
    return data.exclusionDates;
    // return groups.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const addOrgMargin = async (rootId: number, margin: number | null, token: string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ rootId, margin }),
  };

  try {
    const res = await fetch(tutoringApiUrl() + '/_internal/license/addOrgMargin', requestOptions);
    const data = await res.json();
    return data.margin;
    // return groups.json();
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const createMultipleSessions = async (
  groupId: number,
  timeFrame: TimeFrame,
  GSELevels: GSELevel[],
  token: string,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ groupId, timeFrame, GSELevels }),
  };

  try {
    const res = await fetch(
      tutoringApiUrl() + '/_internal/sessions/createMultipleSessions',
      requestOptions,
    );
    const data = await res.json();
    return data;
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};

export const removeAllUpcomingSessionsPrivate = async (
  startDate: string, // YYYY-MM-DD
  groupId: number,
  token: string,
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ startDate, groupId }),
  };
  console.log('removeAllUpcomingSessionsPrivate: ', startDate, groupId);

  try {
    const res = await fetch(
      tutoringApiUrl() + '/_internal/sessions/removeAllUpcomingSessionDev',
      requestOptions,
    );
    const data = await res.json();
    return data;
  } catch (e) {
    console.log('error:', e);
    // throw e;
  }
};
